import { useTranslation } from 'react-i18next';
import { useFormatNumber } from '@zeal/zeal-lib';

import { useBusinessProperties } from '../BusinessAccount/useBusinessInfo';

export function useCurrency() {
	const { data } = useBusinessProperties();
	const { t } = useTranslation('common');

	const { formatCurrency: libFormatCurrency } = useFormatNumber();

	const currencyCode = data?.currency || 'EGP';

	const translatedCurrency = t(currencyCode?.toLowerCase());

	const formatCurrency = (
		value: number,
		fractionDigits: number = 2,
		options?: Intl.NumberFormatOptions
	) => {
		return libFormatCurrency(value, currencyCode, fractionDigits, options);
	};

	return { currencyCode, currency: translatedCurrency, formatCurrency };
}
