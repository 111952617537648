import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';
import { MicroLoyaltyStrategy } from './types';

export const LoyaltyProgrammeRoutes = {
	LOYALTY_PROGRAMME: route(
		'loyalty',
		{},
		{
			SETUP: route(
				'setup',
				{},
				{
					SETTINGS: route('settings', {
						state: {
							microloyalty_type: yup(
								Yup.mixed<MicroLoyaltyStrategy>()
									.oneOf(Object.values(MicroLoyaltyStrategy))
									.optional()
							),
						},
					}),
					LOYALTY_EXISTENCE_QUESTION: route('loyalty-existence', {}),
					CHANNEL_OPTIONS: route(
						'channel',
						{},
						{
							LOYALTY_STRATEGY: route('strategy', {}),
							PREVIEW: route(
								'preview',
								{},
								{
									CHANNEL: route(':channel', {
										params: {
											channel: yup(Yup.number()),
										},
									}),
								}
							),
						}
					),
					LOYALTY_LINK: route('loyalty-link', {}),
				}
			),
			REPORTS: route('reports', {}),
			DOWNLOADS: route('downloads', {}),
		}
	),
};
