import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Button,
	Flex,
	Icon,
	Label,
	ParentShimmer,
	StatefulContainer,
} from '@zeal/web-ui';

import {
	StyledContentWithBorderBottom,
	StyledLinedText,
	StyledPlanPriceStrongText,
	StyledPlanTextWrapper,
} from '../StyledComponents';
import CheckField from '@modules/FreemiumAnalytics/components/UpgradePlan/components/CheckField';
import { useFetchTranslation } from '@app/hooks';
import { useGetSubscriptionDetails } from '@app/data/Settings/useGetBillingAndPaymentsDetails';
import { useCurrency } from '@app/hooks/useCurrency';

interface PlanSectionContent {
	btnText?: string;
	btnOnClick?: () => void;
	isSettingsPlanPage?: boolean;
	sectionContent?: React.ReactNode;
}
const PlanFeaturesCard: React.FC<PlanSectionContent> = ({
	btnText,
	btnOnClick,
	isSettingsPlanPage,
	sectionContent,
}) => {
	const { isTranslationsLoading } = useFetchTranslation('freemiumLoyalty');
	const { t } = useTranslation('freemiumLoyalty');

	return (
		<ParentShimmer isLoading={isTranslationsLoading}>
			<Flex
				isColumn
				className="border border-solid border-[rgba(201, 204, 207, 0.60)] basis-1/2"
				style={{
					borderRadius: '8px',
					maxWidth: '488px',
					width: '50%',
					minWidth: '350px',
				}}
			>
				{!isSettingsPlanPage && (
					<StyledContentWithBorderBottom className="p-6">
						<Flex gap="2">
							<StyledPlanTextWrapper isColumn gap="1">
								<Label.Big500 variant="default">
									{t('premium_plan')}
								</Label.Big500>
								<Label.Thin200 variant="default">
									{t('premium_plan_description')}
								</Label.Thin200>
							</StyledPlanTextWrapper>
							<PlanPricing />
						</Flex>
					</StyledContentWithBorderBottom>
				)}

				{sectionContent ? (
					sectionContent
				) : (
					<div className="p-6">
						<Flex isColumn gap="6">
							<Flex isColumn gap="1">
								<Label.Mid400>{t('features')}</Label.Mid400>
								<Label.Thin200 variant="subdued">
									{t('features_subtitle')}
								</Label.Thin200>
							</Flex>
							<Flex isColumn gap="3">
								{features.map((feat) => (
									<CheckField
										key={feat.title}
										label={t(feat.title)}
										isComingSoon={feat.isComingSoon}
										comingSoonLabel={t(feat.comingSoonLabel)}
									/>
								))}
							</Flex>
						</Flex>
					</div>
				)}

				{btnOnClick && (
					<>
						<hr />
						<div className="p-6">
							<Button
								size="lg"
								variant="primary"
								className="!w-full"
								onClick={btnOnClick}
							>
								{btnText}
							</Button>
						</div>
					</>
				)}
			</Flex>
		</ParentShimmer>
	);
};

const PRICE_MARGIN = 20;
const PlanPricing = () => {
	const { t } = useTranslation('freemiumLoyalty');
	const { formatCurrency: _formatCurrency } = useCurrency();

	const {
		data: subscriptionDetailsData,
		isLoading: isSubscriptionDetailsLoading,
		isError: isSubscriptionDetailsError,
	} = useGetSubscriptionDetails();

	const formatCurrency = (value: number) =>
		subscriptionDetailsData?.currency
			? _formatCurrency(value, 0, {
					currency: subscriptionDetailsData?.currency,
			  })
			: value;

	const isTerminalLevel =
		subscriptionDetailsData?.pricing_plan === 'terminal-level' ||
		Boolean(subscriptionDetailsData?.card_machines);

	const strikeThroughPrice = isTerminalLevel
		? formatCurrency(
				(Number(subscriptionDetailsData?.amount) + PRICE_MARGIN) *
					subscriptionDetailsData?.card_machines!
		  )
		: formatCurrency(Number(subscriptionDetailsData?.total) + PRICE_MARGIN);

	return (
		<StatefulContainer
			isError={isSubscriptionDetailsError}
			cardProps={{
				hasBorder: false,
				shadow: 'none',
			}}
			errorContent={
				<Flex justify="center" align="center" isColumn gap="1">
					<Icon
						variant="regular"
						iconName="triangleExclamation"
						colorVariant="danger"
						size="lg"
					/>
					<Label.Thin100 variant="subdued" textAlign="center">
						{t('common:something_went_wrong')}
					</Label.Thin100>
				</Flex>
			}
		>
			<Flex
				isColumn
				className="min-w-fit items-end"
				gap={isSubscriptionDetailsLoading ? '2' : '0'}
			>
				<ParentShimmer isLoading={isSubscriptionDetailsLoading}>
					<StyledLinedText>
						{strikeThroughPrice}
						{t('per_month')}
					</StyledLinedText>
				</ParentShimmer>

				<ParentShimmer isLoading={isSubscriptionDetailsLoading}>
					<Flex align="baseline">
						<StyledPlanPriceStrongText>
							{formatCurrency(subscriptionDetailsData?.total)}
						</StyledPlanPriceStrongText>
						<Label.Thin200 variant="subdued">{t('per_month')}</Label.Thin200>
					</Flex>
				</ParentShimmer>
				{isTerminalLevel && (
					<Label.Thin100 variant="subdued">
						{t('card_machines_per_month', {
							count: subscriptionDetailsData?.card_machines,
							price: formatCurrency(Number(subscriptionDetailsData.amount)),
						})}
					</Label.Thin100>
				)}
			</Flex>
		</StatefulContainer>
	);
};

export default PlanFeaturesCard;

export const features = [
	{
		title: 'premium_plan_feat1',
	},
	{
		title: 'premium_plan_feat2',
	},
	{
		title: 'premium_plan_feat3',
	},
	{
		title: 'premium_plan_feat4',
	},
	{
		title: 'premium_plan_feat5',
	},
	{
		title: 'premium_plan_feat6',
		isComingSoon: true,
		comingSoonLabel: 'coming_soon',
	},
];
