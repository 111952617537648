import React from 'react';
import { Flex, Icon, Label, palette } from '@zeal/web-ui';
import { StyledComingSoonWrapper } from '@modules/LoyaltyProgramme/components/styledComponents';

const CheckField = ({
	label,
	isComingSoon,
	comingSoonLabel,
}: {
	label: string;
	isComingSoon?: boolean;
	comingSoonLabel?: string;
}) => {
	return (
		<Flex align="center" gap="3">
			<Icon
				iconName={isComingSoon ? 'infoCircle' : 'circleCheck'}
				colorVariant={isComingSoon ? 'primary' : 'secondary'}
				variant="regular"
				size="lg"
			/>
			<Label.Mid200 variant={isComingSoon ? 'subdued' : 'default'}>
				{label}
			</Label.Mid200>
			{isComingSoon && (
				<StyledComingSoonWrapper>
					<Label.Thin100 variant="primary">{comingSoonLabel}</Label.Thin100>
				</StyledComingSoonWrapper>
			)}
		</Flex>
	);
};

export default CheckField;
