import React from 'react';
import { Button, CustomModal, Flex, Icon, Image, Label } from '@zeal/web-ui';
import { usePaymentStore } from './paymentStore';
import bg from './upgrade-success.png';
import { useTranslation } from 'react-i18next';
import { StyledComingSoonWrapper } from '@modules/LoyaltyProgramme/components/styledComponents';
import { features } from '@app/Components/UpgradeFeaturesLandingPage/components/PlanFeaturesCard';

export const UpgradeSuccessModal = ({ isOpen }: { isOpen: boolean }) => {
	const { t } = useTranslation('freemiumLoyalty');

	const setShowUpgradePlanModal = usePaymentStore(
		(store) => store.setShowUpgradePlanModal
	);
	return (
		<CustomModal
			isOpen={isOpen}
			contentClassName={'!shadow-none !top-1/2'}
			overClassName="background: rgba(0, 0, 0, 0.25)"
			content={
				<div
					className="bg-white rounded-2xl relative"
					style={{
						minWidth: '720px',
						minHeight: '460px',
					}}
				>
					<Icon
						variant="regular"
						iconName="xMark"
						size="xl"
						className="absolute top-4 end-4 cursor-pointer"
						onClick={() => setShowUpgradePlanModal(false)}
					/>
					<Flex justify="between" align="start">
						<Flex isColumn gap="5" className="py-12 ps-12 pe-6">
							<Flex isColumn gap="2">
								<Label.Big500>{t('welcome_to_zeal_premium')}</Label.Big500>
								<Label.Mid200 className="whitespace-break-spaces">
									{t('successfully_upgraded_subtitle')}
								</Label.Mid200>
							</Flex>
							<Flex isColumn gap="3">
								{features.map(({ title, isComingSoon, comingSoonLabel }) => (
									<Flex gap="1" align="center" key={title}>
										<Icon
											variant="regular"
											iconName={isComingSoon ? 'infoCircle' : 'circleCheck'}
											colorVariant={isComingSoon ? 'primary' : 'success'}
										/>
										<Label.Thin100
											variant={isComingSoon ? 'subdued' : 'default'}
										>
											{t(title)}
										</Label.Thin100>
										{isComingSoon && (
											<StyledComingSoonWrapper>
												<Label.Thin100 variant="primary">
													{t(comingSoonLabel)}
												</Label.Thin100>
											</StyledComingSoonWrapper>
										)}
									</Flex>
								))}
							</Flex>
							<div className="mt-3">
								<Button
									variant="primary"
									size="lg"
									onClick={() => setShowUpgradePlanModal(false)}
								>
									{t('upgradePlan:lets_get_started')}
								</Button>
							</div>
						</Flex>
						<Image src={bg} style={{ height: '460px' }} />
					</Flex>
				</div>
			}
		/>
	);
};
