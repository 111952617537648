import { EMetricChange } from '@zeal/zeal-lib';

export type TFreemiumUser = {
	uuid: string;
	name: string;
	email: string;
	created_at: string;
};

export enum LoyaltyChannel {
	Classic = 1,
	Web = 2,
	Micro = 3,
	Whitelabel = 4,
}

export enum MicroLoyaltyStrategy {
	Points = 'points',
	StampCards = 'stamp_cards',
}

export type TUpdateLoyaltyChannel = {
	loyalty_channel: LoyaltyChannel;
};

export type TApiFreemiumAddUserForm = {
	uuid?: string;
	name: string;
	email: string;
};

export interface TLoyaltySettings {
	id: string;
	model: string;
	action: 'store' | 'dry'; // store for submit or dry for preview
	points_expire_in_months?: number | string;
	points_earn_threshold?: number;

	earn_rate?: number | null;
	points_redemption_duration_in_days?: number | null;
	vouchers: {
		price_in_points?: number | null;
		value: number;
		expiration_duration_in_months: number | string;
		terms_and_conditions: string;
	}[];
	stamps_to_voucher?: number | string;
	loyalty_channel: {
		id: LoyaltyChannel;
		name: string | null;
	};
	loyalty_strategy?: MicroLoyaltyStrategy;
	stamps_expiry_in_months?: number | string;
}

export interface TApiExport {
	type: string;
	date: apiDate;
	status_value: string;
	status_key: TApiExportStatus;
	download_link: string;
}

export type TApiExportStatus = 'finished' | 'in_progress' | 'timeout';

export interface TLoyaltyForm {
	earn_cash: number;
	earn_points: number;
	burn_cash: number;
	burn_points: number;
	threshold: number;
	duration: number;
	points_expiry: number;
	voucher_expiry: number;
	terms?: string;
}

export type PeriodFilter = 'week' | 'month' | 'custom';

export type TMemberInsights = {
	total_loyalty_members: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
		metadata: {
			loyalty_members_line_chart: {
				label: string;
				data: {
					labels: string[];
					datasets: [
						{
							label: string;
							data: number[];
						}
					];
				};
			};
		};
	};
	total_active_members: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
		metadata: {
			active_members_line_chart: {
				label: string;
				data: {
					labels: string[];
					datasets: [
						{
							label: string;
							data: number[];
						}
					];
				};
			};
		};
	};
	smart_saver_members: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
		metadata: {
			smart_saver_line_chart: {
				label: string;
				data: {
					labels: string[];
					datasets: [
						{
							label: string;
							data: number[];
						}
					];
				};
			};
		};
	};
};

export type TPointsInsights = {
	points_earned: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
	};
	voucher_redeemed: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
	};
	points_burned: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
	};
	points_expired: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
	};
	cashback_expired: {
		title: string;
		value: number;
		change: {
			type: EMetricChange;
			value: string;
		};
		options: {
			uses_currency: boolean;
			is_empty: boolean;
		};
	};
};

export type TEarnedReports = {
	customer_uuid: string;
	transaction_uuid: string;
	receipt_value: number;
	points_earned: number;
	date: {
		normal: string;
		iso: string;
		date: string;
	};
};

export type TBurnedReports = {
	customer_uuid: string;
	points_burned: number;
	voucher_value: number;
	date_earned: apiDate;
	date_burned: apiDate;
};

export type TRedeemedReports = {
	customer_uuid: string;
	transaction_uuid: string;
	receipt_value: number;
	voucher_value: number;
	date_earned: apiDate;
	date_redeemed: apiDate;
};

export type TPointsExpiredReports = {
	customer_uuid: string;
	points_expired: number;
	date_earned: apiDate;
	date_expired: apiDate;
};

export type TVouchersExpiredReports = {
	customer_uuid: string;
	points_burned: number;
	voucher_value: number;
	date_burned: apiDate;
	date_expired: apiDate;
};

export type TLoyaltyLinkForm = {
	provider?: string;
	app_link?: string;
};
