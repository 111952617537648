import styled from 'styled-components';

import { Flex, palette, Label, Tabs } from '@zeal/web-ui';

import pos from '../components/LoyaltySettings/assets/POS.png';
import { Swiper } from 'swiper/react';

export const StyledComingSoonWrapper = styled.div`
	background: ${palette.surface.primary.default};
	border-radius: 0.5rem;
	padding: 0 6px;
`;

export const LoyaltyPlanOptionCard = styled.div`
	display: flex;
	max-width: 1280px;
	padding: 20px 24px 24px 24px;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	align-self: stretch;
	background: ${palette.surface.default};
`;

export const LoyaltyPlanOptionsRow = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	gap: 24px;
	align-self: stretch;
	flex-wrap: wrap;
`;

export const StyledTabTrigger = styled(Tabs.Trigger)`
	border: none;
	background: none;
	&:hover {
		background: none;
	}
`;

export const StyledPOS = styled(Flex)`
	background: url(${pos});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 430px;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
	gap: 6px;
`;

export const StylesCustomGap = styled(Flex)`
	gap: 6px;
`;
export const StyledSmallLabel = styled(Label)`
	font-size: 9px;
	font-weight: 700;
	line-height: 140%; /* 12.6px */
	letter-spacing: -0.27px;
	max-width: 120px;
`;

export const StyledExtraSmallLabel = styled(Label)`
	font-size: 8px;
	font-weight: 500;
	max-width: 120px;
	line-height: 140%; /* 12.6px */
	letter-spacing: -0.27px;
	display: block;
`;

export const StyledSwiper = styled(Swiper)`
	width: 100%;
	.swiper-slide {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
	}
`;

export const StyledButtonsWrapper = styled(Flex)`
	gap: 6px;
	position: absolute;
	bottom: 55px;
	left: 145px;
	width: 30%;
`;
export const StyledButton = styled(Flex)`
	height: 18.743px;
	padding: 4.735px;
	align-self: stretch;
	border-radius: 0.592px !important;
	justify-content: center;
`;

export const StyledDangerButton = styled(Flex)`
	height: 18.743px;
	padding: 4.735px;
	border-radius: 0.592px !important;
	border: 0.592px solid #ff2c1a;
	justify-content: center;
`;

export const SettingsPreviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	align-self: stretch;
	position: sticky;
	top: 0;
	height: calc(100vh - var(--main-navbar-width));
	position: sticky;
	top: 0;
	flex-shrink: 0;
`;

export const SettingsPreview = styled.div`
	display: flex;
	min-width: 400px;
	width: 100%;
	flex-direction: column;
	flex: 1 0 0;
	align-items: flex-start;
	align-self: stretch;
`;

export const SettingsPreviewHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-self: stretch;
	border-radius: 8px 8px 0px 0px;
	padding: 24px 24px 0px 24px;
	background: ${palette.surface.default};
`;

export const SettingsPreviewContent = styled.div`
	display: flex;
	min-height: 340px;
	max-height: calc(90vh - var(--main-navbar-width));
	flex-direction: column;
	align-items: flex-start;
	padding: 0px 24px 24px 24px;
	gap: 24px;
	flex: 1 0 0;
	align-self: stretch;
	background: ${palette.surface.default};
	overflow-y: scroll;
`;

export const SettingsPreviewButtonContainer = styled.div`
	display: flex;
	padding: 24px;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	align-self: stretch;
	border-radius: 0px 0px 8px 8px;
	border-top: ${palette.divider.default};
	background: ${palette.surface.default};
	box-shadow: 0px -1px 24px 0px rgba(0, 0, 0, 0.08);
`;
