import { create } from 'zustand';
import { Channel } from 'pusher-js';

export const usePusherStore = create<{
	mainChannel: Channel | null;
	setMainChannel: (channel: Channel) => void;
}>()((set) => ({
	mainChannel: null,
	setMainChannel: (channel) => set({ mainChannel: channel }),
}));
