import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface PaymentStore {
	isLoading: boolean;
	setIsLoading: (value: boolean) => void;
	paymentError: string | null;
	setPaymentError: (value: string | null) => void;
	showUpgradePlanModal: boolean;
	setShowUpgradePlanModal: (value: boolean) => void;
}

export const usePaymentStore = create<PaymentStore>()(
	devtools(
		(set) => ({
			isLoading: false,
			setIsLoading: (value: boolean) => set({ isLoading: value }),
			paymentError: null,
			setPaymentError: (value: string | null) => set({ paymentError: value }),
			showUpgradePlanModal: false,
			setShowUpgradePlanModal: (value: boolean) =>
				set({ showUpgradePlanModal: value }),
		}),
		{
			name: 'paymentStore',
		}
	)
);
