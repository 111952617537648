import styled from 'styled-components';

import {
	Accordion,
	ControlledInput,
	ControlledSelect,
	Flex,
	Label,
	palette,
} from '@zeal/web-ui';

import footerBg from './assets/footerBg.png';

export const StyledLoyaltyChannelLabel = styled(Label.Thin200)`
	background: ${palette.general.surface.five};
	padding: 4px 8px;
	border-radius: 8px;
	max-width: fit-content;
`;

export const StyledLoyaltyTitle = styled(Label)`
	font-size: 64px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: -1.92px;
	max-width: 478px;
	color: #0f172a;
`;

export const StyledLoyaltyDescription = styled(Label.Thin400)`
	max-width: 390px;
	color: #4b5262;
`;

export const StyledSectionWrapper = styled(Flex)`
	padding: 72px 96px;
`;

export const StyledPremiumFeaturesWrapper = styled(Flex)`
	max-width: 435px !important;
`;

export const StyledPremiumFeatureDescription = styled(Label.Thin200)`
	max-width: 372px;
`;

export const StyledPlanTextWrapper = styled(Flex)`
	max-width: 325px;
`;
export const StyledLinedText = styled(Label)`
	color: ${palette.text.subdued};
	font-size: 16px;
	font-weight: 400;
	line-height: 140%;
	letter-spacing: -0.48px;
	text-decoration-line: line-through;
	text-decoration-color: currentColor;
`;

export const StyledContentWithBorderBottom = styled.div`
	border-bottom: 1px solid ${palette.divider.default};
`;

export const StyledPlanPriceStrongText = styled(Label)`
	color: #0f172a;
	font-size: 36px;
	font-weight: 700;
	line-height: 140%; /* 50.4px */
	letter-spacing: -1.08px;
`;

export const StyledAccordion = styled(Accordion)`
	max-width: 880px;
	padding: 12px;
`;

export const StyledAccordionWrapper = styled(Flex)`
	margin: 0 30px;
`;

export const StyledFooterWrapper = styled(Flex)`
	background: url(${footerBg});
	background-size: cover;
	background-position: center center;
	margin: -0.75rem;
	padding: 24px;
	color: ${palette.text.white.default};
	gap: 40px;
	width: 100%;
`;

export const StyledFooterStartConversation = styled(Flex)`
	background: ${palette.surface.default};
	border-radius: 6.712px;
	padding: 20.14px;
	width: 820px;
`;

export const StyledHalfWidthInput = styled(ControlledInput)`
	width: 50% !important;
`;
export const StyledHalfWidthSelect = styled(ControlledSelect)`
	width: 50% !important;
`;
