import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Outlet } from 'react-router-dom';

export const LoyaltyProgrammePathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.LOYALTY_PROGRAMME.path,
			element: <Outlet />,
			children: [
				{
					path: ROUTES.LOYALTY_PROGRAMME.$.SETUP.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./LoyaltySetup')),
						},
						{
							path: ROUTES.LOYALTY_PROGRAMME.SETUP.$.SETTINGS.relativePath,
							element: lazy(() => import('./LoyaltySettings')),
						},
						{
							path: ROUTES.LOYALTY_PROGRAMME.SETUP.$.LOYALTY_EXISTENCE_QUESTION
								.relativePath,
							element: lazy(
								() =>
									import('./components/LoyaltySetup/LoyaltyExistenceQuestions')
							),
						},
						{
							path: ROUTES.LOYALTY_PROGRAMME.SETUP.$.CHANNEL_OPTIONS
								.relativePath,
							element: <Outlet />,
							children: [
								{
									index: true,
									element: lazy(
										() =>
											import('./components/LoyaltySetup/LoyaltyChannelOptions')
									),
								},
								{
									path: ROUTES.LOYALTY_PROGRAMME.SETUP.CHANNEL_OPTIONS.$
										.LOYALTY_STRATEGY.relativePath,
									element: lazy(
										() =>
											import('./components/LoyaltySetup/MicroLoyaltyStrategy')
									),
								},
								{
									path: ROUTES.LOYALTY_PROGRAMME.SETUP.CHANNEL_OPTIONS.$.PREVIEW
										.CHANNEL.relativePath,
									element: lazy(
										() =>
											import('./components/LoyaltySetup/LoyaltyModelPreview')
									),
								},
							],
						},
						{
							path: ROUTES.LOYALTY_PROGRAMME.SETUP.$.LOYALTY_LINK.relativePath,
							element: lazy(
								() => import('./components/LoyaltySetup/LoyaltyLink')
							),
						},
					],
				},
				{
					path: ROUTES.LOYALTY_PROGRAMME.$.REPORTS.relativePath,
					element: lazy(() => import('./LoyaltyReports')),
				},
				{
					path: ROUTES.LOYALTY_PROGRAMME.$.DOWNLOADS.relativePath,
					element: lazy(() => import('./Downloads')),
				},
			],
		},
	],
};
