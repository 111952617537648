import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

function UnAuthorized(props: any) {
	const location = useLocation();

	const { unauthorizedRender, unauthorizedComponent, unauthorizedRedirect } =
		props;

	if (unauthorizedRender) {
		return unauthorizedRender?.();
	}

	if (unauthorizedRender) {
		const UnAuth = unauthorizedComponent;

		return <UnAuth {...props} />;
	}

	return (
		<Navigate {...redirectToState(unauthorizedRedirect, location.pathname)} />
	);
}

const redirectToState = (pathname = '/login', from: string): NavigateProps => ({
	to: { pathname: pathname },
	state: {
		unauthorizedRedirect: true,
		shouldGoBack: true,
		from,
	},
});

export default React.memo(UnAuthorized);
